<template>
  <div>
    <div class="text-c marc20 font24 color-ffa">子午流柱</div>
    <div class="u-p-t-80 flex-cen">
      <div class="m-top flex-cen po-rela">
        <!-- /static/zwlz/bj_44@2x.png" -->
        <img style="width: 550px" src="../assets/images/bj_44@2x.png" />
        <div class="mt-ring mt-dot">
          <!-- /static/zwlz/bj-47@2x.png -->
          <img
            :style="{
              transform: `rotate(${jaodu[repairCur(current)] - 0 + 45}deg)`,
              width: '499px',
            }"
            src="../assets/images/bj-47@2x.png"
          />
        </div>
        <div class="mt-ring mt-con hand">
          <div class="mtc-ul">
            <div
              v-for="(li, inx) in data"
              class="flex-cen mtc-li"
              @click="demo(inx)"
              :key="inx"
              :class="current == repairInx(inx) ? 'li-ac' : ''"
              :style="{
                transform: `rotate(${repairCur(inx) * 30}deg)`,
              }"
            >
              <div
                class="flex-cen"
                :style="{
                  transform: `rotate(-${repairCur(inx) * 30 - 15}deg)`,
                }"
              >
                {{ li.text }}
              </div>
            </div>
            <div
              class="mtcu-mask"
              :style="{
                backgroundImage: `url(${
                  isRoll ? accImg : '../assets/images/bj-1.png'
                })`,
                transform: `translate(-50%, -50%) rotate(${
                  jaodu[repairCur(current)] - 0 + 45
                }deg)`,
              }"
            ></div>
          </div>
        </div>
        <div class="mt-ring mt-img">
          <img
            :style="{ transform: `rotate(${changeNum * 360}deg)` }"
            src="../assets/images/bj_47@2x.png"
          />
        </div>
      </div>
    </div>
    <div class="u-p-t-70 flex-cen font20 color-333 weight">
      {{
        (info[repairCur(current)] && info[repairCur(current)].chinaTime) || ""
      }}时
    </div>
    <div class="font18 color-666 m-text">
      {{
        (info[repairCur(current)] && info[repairCur(current)].description) || ""
      }}
    </div>
    <div style="margin: 20px 19%">
      <div class="pad8" style="width: 600px">
        <Carousel
          v-model="value3"
          :autoplay="setting.autoplay"
          :autoplay-speed="setting.autoplaySpeed"
          :dots="setting.dots"
          :radius-dot="setting.radiusDot"
          :trigger="setting.trigger"
          :arrow="setting.arrow"
          style="height: 180px; background: #fa9f2f; text-align: center"
          @on-change="changeSwiper"
        >
          <CarouselItem v-for="item in info" :key="item.chinaTime">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div class="demo-carousel font20 marc20" style="color: #fff">
                {{ item.chinaTime }}
              </div>
              <div class="demo-carousel font14 mar10" style="color: #fff">
                {{ item.meridians }}
              </div>
            </div>
            <div class="demo-carousel font14 marc10" style="color: #fff">
              {{ item.startHour }}时-{{ item.endHour }}时
            </div>
            <div class="demo-carousel font14" style="color: #fff">
              {{ item.shortDesc }}
            </div>
          </CarouselItem>
        </Carousel>
        <!-- <swiper
          class="swiper swiper_box"
          :current="current"
          circular
          :autoplay="autoplay"
          display-multiple-items="3"
          :duration="duration"
          @change="change"
        >
          <swiper-slide
            class="mb-li flex-cen"
            v-for="(item, inx) in info"
            :key="inx"
            @click="current = repairInx(inx)"
          >
            <div
              class="swiper-item fz24 color-999 pad28 u-p-b-30"
              :class="[repairInx(inx) == current ? 'swi-ac color-fff' : '']"
            >
              <div class="u-p-t-34">
                <text
                  class="fz36 color-333"
                  :class="[repairInx(inx) == current ? 'color-fff' : '']"
                  >{{ item.chinaTime }}</text
                >
                <text class="u-m-l-4">{{ item.meridians }}</text>
              </div>
              <div class="fz20 u-m-t-26">
                {{ item.startHour }}时-{{ item.endHour }}时
              </div>
              <div class="u-m-t-22">
                <div class="u-p-t-8" v-for="li in item.desc">{{ li }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper> -->
      </div>
    </div>
  </div>
</template>

<script>
import { meridionalFlow } from "@/api/cyclo";
import accImg from "@/assets/images/bj-1-ac.png";
export default {
  data() {
    return {
      value3: 0,
      accImg,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: "inside",
        radiusDot: false,
        trigger: "click",
        arrow: "hover",
      },
      duration: 1500,
      autoplay: false,
      current: 11,
      info: [],
      curData: {},
      data: [
        { text: "丑", jd: "-15", st: 1 },
        { text: "寅", jd: "15", st: 3 },
        { text: "卯", jd: "45", st: 5 },
        { text: "辰", jd: "75", st: 7 },
        { text: "巳", jd: "105", st: 9 },
        { text: "午", jd: "135", st: 11 },
        { text: "未", jd: "165", st: 13 },
        { text: "申", jd: "195", st: 15 },
        { text: "酉", jd: "225", st: 17 },
        { text: "戌", jd: "255", st: 19 },
        { text: "亥", jd: "285", st: 21 },
        { text: "子", jd: "315", st: 23 },
      ],
      jaodu: [],
      colors: [
        "#ffff00",
        "#55aaff",
        "#aaffff",
        "#5500ff",
        "#aa55ff",
        "#aaffff",
        "#00aa7f",
        "#ff5500",
        "#55ff00",
        "#bfe684",
        "#aa3642",
        "#ffaaff",
      ],
      changeNum: 0, // 列表改变的次数
      isRoll: true, // 切换列表在滚动中
    };
  },
  mounted() {
    this.jaodu = this.data.map((item) => item.jd);
    this.getInfo();

    let date = new Date();
    let hour = date.getHours();
    let st = this.data.map((item) => item.st);
    for (let i = 0; i < st.length; i++) {
      if (st[i] > hour) {
        this.current = i - 2;
        this.value3 = i - 1;
        break;
      } else if (hour <= 24) {
        this.current = i - 1;
        this.value3 = i - 1;
      }
    }
  },
  watch: {
    current(val) {
      this.changeNum++;
      // this.isRoll = true;
      setTimeout(() => {
        // this.isRoll = false;
      }, 500);
    },
  },
  methods: {
    changeSwiper(oldVal, val) {
      this.current = val - 1;
    },
    demo(inx) {
      this.current = this.repairInx(inx);
      console.log("点击列表");
      this.value3 = inx;
    },
    demo2() {
      console.log("指针点击");
    },
    getInfo() {
      let oldTime = this.data.map((item) => item.text);
      meridionalFlow({}).then((res) => {
        let arr = [];
        res.data.forEach((item) => {
          item.shortDesc = item.shortDesc || "";
          let desc = item.shortDesc.split("。")[0];
          item.desc = desc.split("，");

          let inx = oldTime.indexOf(item.chinaTime);
          arr[inx] = item;
        });
        this.info = arr;
      });
    },
    change(val) {
      let { current } = val.detail;
      this.current = current;
    },
    repairInx(inx) {
      let len = this.info.length; // this.info.length // 数据长度
      if (inx == 0) return len - 1;
      return inx - 1;
    },
    repairCur(inx) {
      let len = this.info.length; // this.info.length // 数据长度
      if (inx == len - 1) return 0;
      return inx + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.m-top {
  width: 564px;
  height: 564px;
  // background-color: #ffaa7f;
  .mt-ring {
    width: 88%;
    height: 88%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mt-dot {
    img {
      transition: all 0.5s;
    }
  }
  .mt-con {
    width: 74%;
    height: 74%;
    .mtc-ul {
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      top: 0px;
      left: -2px;
      background-color: #f0f0f0;
      border-radius: 50%;
      border: 4px solid #f0f0f0;
      color: #8a8a8a;
      transform: rotate(-15deg);
      .mtc-li {
        position: absolute;
        left: 152px;
        width: 0px;
        height: 0px;
        border-top: 209px solid #f8f8f8;
        border-left: 54px solid transparent;
        border-right: 54px solid transparent;
        transform-origin: center bottom;
        box-sizing: border-box;
        & > div {
          position: absolute;
          top: -208px;
          font-size: 34px;
          height: 60px;
        }

        &:nth-child(1) > div,
        &:nth-child(11) > div {
          top: -212px;
        }
        &:nth-child(3) > div,
        &:nth-child(6) > div,
        &:nth-child(7) > div,
        &:nth-child(8) > div {
          top: -204px;
        }
        &:nth-child(4) > div,
        &:nth-child(5) > div {
          top: -200px;
        }
      }
      .li-ac {
        // background-image: url(/static/zwlz/item-ac.png);
        // background-color: #aaaaaa;
        border-top-color: #aaaaaa;
        color: #fff;
      }
      .mtcu-mask {
        background-color: #fff;
        border: 4px solid #f0f0f0;
        position: absolute;
        width: 307px;
        height: 307px;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        overflow: hidden;
        transform: translate(-50%, -50%);
        transition: all 0.5s;
        background-size: 90% 90%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  .mt-arrow {
    width: 22%;
    height: 22%;
    transform: translate(20%, -100%);
    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -20px;
    }
  }
  .mt-pointer {
    width: 90%;
    height: 90%;
    // background-color: pink;
    border-radius: 50%;
    img {
      transition: all 0.5s;
      border-radius: 50%;
    }
  }
  .mt-img {
    width: 31.5%;
    height: 31%;
    img {
      transition: all 0.5s;
    }
  }
}
.m-text {
  line-height: 60px;
  text-align: center;
  padding: 30px 80px 0;
  // height: 150px;
}
.mb-li {
  & > div {
    width: 200px;
    border-radius: 20px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
  }
}
.swiper_box {
  // display: flex;
}
//

.swiper-box {
  width: 80%;
  height: 35rem;
  margin: 2rem auto;
  position: relative;
}
.swiper-box .swiper {
  width: 100%;
  height: 100%;
}
.swiper-box .swiper-pagination {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
.swiper .swiper-slide {
  background-color: #ffffff;
  box-shadow: 0.02rem 0.1rem 1.78rem 0.02rem rgba(0, 0, 0, 0.09);
}
.item-img {
  width: 100%;
  height: 17.5rem;
  position: relative;
}
.item-title {
  position: absolute;
  right: 2rem;
  bottom: 0.5rem;
  font-size: 1.7rem;
  color: #d3a359;
}
.b {
  background-color: #d3a359;
  width: 2rem;
  height: 0.2rem;
  position: absolute;
  right: 2rem;
  bottom: 0;
}
.item-text {
  width: 100%;
  height: 17.5rem;
  position: relative;
}
.item-text p {
  width: 80%;
  height: 15.5rem;
  margin: 0 auto;
  padding: 2rem 0 0;
  line-height: 2rem;
}
.item-btn {
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 0.3rem;
  border: solid 0.1rem #d3a359;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4rem;
  margin: auto;
  font-size: 1.2rem;
  color: #d3a359;
}
</style>