var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-c marc20 font24 color-ffa"},[_vm._v("子午流柱")]),_c('div',{staticClass:"u-p-t-80 flex-cen"},[_c('div',{staticClass:"m-top flex-cen po-rela"},[_c('img',{staticStyle:{"width":"550px"},attrs:{"src":require("../assets/images/bj_44@2x.png")}}),_c('div',{staticClass:"mt-ring mt-dot"},[_c('img',{style:({
            transform: `rotate(${_vm.jaodu[_vm.repairCur(_vm.current)] - 0 + 45}deg)`,
            width: '499px',
          }),attrs:{"src":require("../assets/images/bj-47@2x.png")}})]),_c('div',{staticClass:"mt-ring mt-con hand"},[_c('div',{staticClass:"mtc-ul"},[_vm._l((_vm.data),function(li,inx){return _c('div',{key:inx,staticClass:"flex-cen mtc-li",class:_vm.current == _vm.repairInx(inx) ? 'li-ac' : '',style:({
              transform: `rotate(${_vm.repairCur(inx) * 30}deg)`,
            }),on:{"click":function($event){return _vm.demo(inx)}}},[_c('div',{staticClass:"flex-cen",style:({
                transform: `rotate(-${_vm.repairCur(inx) * 30 - 15}deg)`,
              })},[_vm._v(" "+_vm._s(li.text)+" ")])])}),_c('div',{staticClass:"mtcu-mask",style:({
              backgroundImage: `url(${
                _vm.isRoll ? _vm.accImg : '../assets/images/bj-1.png'
              })`,
              transform: `translate(-50%, -50%) rotate(${
                _vm.jaodu[_vm.repairCur(_vm.current)] - 0 + 45
              }deg)`,
            })})],2)]),_c('div',{staticClass:"mt-ring mt-img"},[_c('img',{style:({ transform: `rotate(${_vm.changeNum * 360}deg)` }),attrs:{"src":require("../assets/images/bj_47@2x.png")}})])])]),_c('div',{staticClass:"u-p-t-70 flex-cen font20 color-333 weight"},[_vm._v(" "+_vm._s((_vm.info[_vm.repairCur(_vm.current)] && _vm.info[_vm.repairCur(_vm.current)].chinaTime) || "")+"时 ")]),_c('div',{staticClass:"font18 color-666 m-text"},[_vm._v(" "+_vm._s((_vm.info[_vm.repairCur(_vm.current)] && _vm.info[_vm.repairCur(_vm.current)].description) || "")+" ")]),_c('div',{staticStyle:{"margin":"20px 19%"}},[_c('div',{staticClass:"pad8",staticStyle:{"width":"600px"}},[_c('Carousel',{staticStyle:{"height":"180px","background":"#fa9f2f","text-align":"center"},attrs:{"autoplay":_vm.setting.autoplay,"autoplay-speed":_vm.setting.autoplaySpeed,"dots":_vm.setting.dots,"radius-dot":_vm.setting.radiusDot,"trigger":_vm.setting.trigger,"arrow":_vm.setting.arrow},on:{"on-change":_vm.changeSwiper},model:{value:(_vm.value3),callback:function ($$v) {_vm.value3=$$v},expression:"value3"}},_vm._l((_vm.info),function(item){return _c('CarouselItem',{key:item.chinaTime},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('div',{staticClass:"demo-carousel font20 marc20",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(item.chinaTime)+" ")]),_c('div',{staticClass:"demo-carousel font14 mar10",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(item.meridians)+" ")])]),_c('div',{staticClass:"demo-carousel font14 marc10",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(item.startHour)+"时-"+_vm._s(item.endHour)+"时 ")]),_c('div',{staticClass:"demo-carousel font14",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(item.shortDesc)+" ")])])}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }